.bf-ac-c > input{
    border: 1px solid #dddddd;
    padding: 5px 10px;
}

.bf-ac-rc{
    position: relative;
}

.bf-ac-r{
    position: absolute;
    border: 1px solid #dddddd;
    margin-top: -1px;
    background-color: white;
    z-index: 100;
}
.bf-ac-r > div{
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #dddddd;
}
.bf-ac-r > div.active{
background-color: beige;
}
.bf-ac-r > div:last-child{
    border-bottom: none;
}

.bf-ac-ly{
    background-color: rgba(0,0,0,0.3);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: -1px;
    left:-1px;
    z-index: 0;
    backdrop-filter: blur(1px); 
    box-sizing: border-box;
}