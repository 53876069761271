.bfc-co-sc{

}

.bfc-co-sc__container{
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
}

.bfc-co-sc__track{
    height: 30px;
    transition: all 1s;
}

.bfc-co-sc__track--hidden{
    height: 0px;
}

.bfc-co-sc__scoller{
    height: 10px;
    display: inline-block;
    width: 100px;
    background-color:  rgba(255, 255, 255, .5);
    border-radius: 5px;
    position: relative;
}


@supports (-webkit-touch-callout: none) {
    .bfc-co-sc__container{
        overflow-y: overflow; /* Hide vertical scrollbar */
        overflow-x: scroll; /* Hide horizontal scrollbar */
    }
    .bfc-co-sc__scoller{
        display: none;
    }
 }
 