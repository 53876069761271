
body{
    background: linear-gradient(0deg, rgb(19, 152, 156) 0%, rgb(19, 116, 116) 20%, rgb(17, 66, 158) 90%, rgb(0, 37, 105) 100%);
    font-size: x-large;
    height: 100vh;
    color: white;
    font-family:  'Rajdhani', sans-serif; 

  
}

input:focus{
    outline: none;   
}

*{
    box-sizing: border-box;
}

.bf-tzc__city-list{
    text-align: center;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
}

.c-button{
    background-color: rgba(255, 255, 255, 0);
    border: none;
    padding: 6px 10px 5px 10px;
    border-radius: 15px;
    color: #FFFFFF;
    margin: 5px;
    cursor: pointer;
    font-size: .7em;
    transition: all 1s;  
}

.c-button:hover{
    background-color: rgba(255, 255, 255, 0.4);
}


.icon-add{
    width: 50px;
    height: 50px;
    fill: rgba(255, 255,255, 0.5);
    padding-right: 20px;
}
.icon-remove{
    width: 30px;
    height: 30px;
    fill: rgba(255, 255,255, 0.5);
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    transition: all .5s;
}

.icon-remove:hover{
    fill: rgba(255, 255,255, 1);
}

.l-row{
    display: flex;
}

.l-column{
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.l-col-row {

}
.l-col-row--expand {
    flex: 1 1 0; 
}

.--relative{
    position: relative;
}
.--float{

}

.bf-tzc{
}

.bf-tzc__main{
position: absolute;
width: 100vw;
height: 100vh;

}


.bf-tzc__search{
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    
}

.bf-tzc-ac > input{
    align-items: center;
    border: none;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.8);
    position: relative;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    width: 400px;
    border-radius: 30px;
    font-family:  'Dosis', sans-serif;
    font-weight: bold;

}


.bf-tzc-ac > input::placeholder{
    color: rgba(0, 0, 0, 0.5);
    font-style: italic;
}

.bf-tzc__title{
    
    text-align: center;
    font-size: 5vw;
    margin: 0;

    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);

}

.bf-tzc__skyline1{
    width: 100vw;
    height: 30vw;
    position: absolute;
    opacity: 0.5 ;
    bottom:0px;
    left: 0px;
}

.bf-tzc__skyline2{
    width: 100vw;
    height: 16vw;
    position: absolute;
    opacity: 0.5 ;
    bottom:0px;
    left: 0px;
}

.bf-tzc-loc-w{
    border: 1px solid #dddddd;
    box-shadow: 2px 2px 10px #eeeeee;
    margin-bottom: 20px;
    display: flex;
}


.bf-tzc__content-scroller{
    background-color: rgba(255, 255, 255, 0.1);
}

.bf-tzc__clock-list{

}

.bf-tzc__clock-list > div:first-child{
    margin-left : auto;
}

.bf-tzc__clock-list > div:last-child{
    margin-right: auto;
}

.bf-tzc__clock{
    transition: all 0.5s ease-out;   
        padding: 1rem;

}


@keyframes clock-anim-in {
    0% {
      height: 0;
      width: 0;
      transform: scale(0);
       
    }
    15% {
    }

    50%{
        transform: scale(0);
    }
    100% {
        width:300px;
        height: 330px;
        transform: scale(1);
    }
}

@keyframes clock-anim-out {
    0% {
        transform: scale(1);
        width: 300px;
        height: 330px;
  
    }
    25% {
        transform: scale(0);

    }
    50% {


    }
    100% {
        transform: scale(0);
        width: 0px;
        height: 0px;
    }
}

.bf-tzc__clock-main{
    width:300px;
    height: 350px;
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    animation: clock-anim-in 1s ease-out forwards;
}

.bf-tzc__clock-main.hidden{
    animation: clock-anim-out 1s forwards;
}

.my-node-enter {
    animation: clock-anim-out 0s;
  }
  .my-node-enter-active {
    animation: clock-anim-in 1s ease-out;

}
  .my-node-exit {
    animation: clock-anim-in 0s;
  }
  .my-node-exit-active {
    animation: clock-anim-out 1s;
  }

.bf-tzc__clock-wrapper{
    text-align: center;
}

.bf-tzc__clock-wrapper:hover .icon-remove{
}

.bf-tzc__clock-label-info{
    padding: 10px;
    background: rgba(0, 0, 0, 0.4);
    margin: 0 30px;
    border-radius: 30px;
}

.bf-tzc__clock-label{
    font-size: .8em;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;

    letter-spacing: normal;
    padding: 0px 10px;
    font-weight: bold;
    overflow: hidden;
}


.bf-tzc-loc{
    display: flex;
    padding: 20px;
    align-items: center;
}

.bf-tzc-loc>button{
    flex-grow: 0;
}

.bf-tzc-sc-o{
    width: 100%;
    text-align: center;

}



.bf-tzc-ac .bf-ac-r {
    font-size: x-large;
    width: 100%;
    border-radius: 20px;
    color: #666666;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 2px;
    border: 1px solid #999999;
    overflow: hidden;
}

.bf-tzc-ac .bf-ac-r >div {
    font-size: 0.8em;
    padding: 10px 20px;
    color: white;
    border-bottom: 1px solid #888888;

}

.bf-tzc-ac .bf-ac-r >div:hover{
    background-color: rgba(0, 0, 0, 0.7);
}

.bf-tzc-ac .bf-ac-r > div:last-child {
    border-bottom: none;
}

.bf-tzc-loc>label{
    flex-grow: 1;
}

.bf-tzc-loc-time{
    text-align: right;
    padding-right: 20px;
}

.progress {
    width: 100%;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .progress-inner {
    height: 10px;
    background-color: rgba(255, 170, 0, 0.847);
  }
  .progress-inner.blue{
    background-color: rgba(0, 0, 255, 0.9);

  }

  .bfc-clock__background{
    fill: rgb(255, 255, 223);
  }
  .bfc-clock__face-reflection{
    fill: #000;

  }

  .bf-tzc__clock--night .bfc-clock__background{
    fill:#112a7c;
  }

  .bfc-clock__face-control{
    fill: #000 !important;
    stroke: #000;
  }

  .bf-tzc__clock--night .bfc-clock__face-control{
    fill: #eeeeee !important;
    stroke: #FFFFFF !important;
  }

  .bf-tzc__clock--night .bfc-clock__face-reflection{
    fill: #FFF;

  }


  .bl-scroller{
    background-color: rgba(255, 255, 255, 0.2);
    transition: all .5s;
    transition-delay: .5s;
 }
 .bl-scroller.hidden{
    border: transparent;
    background: transparent;
    height: 0px;
 }

 .bf-tzc__offset-label{
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    transition: all .5s;
    transition-delay: .5s;    
 }

 .bf-tzc__offset-label.hidden{
    font-size: 0em;
 }


  @supports (-webkit-touch-callout: none) {
    .bf-tzc__clock-main{
         animation: none;
    }
    
    .bf-tzc__clock-main.hidden{
        animation: none;
    }
    
    .bl-scroller{
        overflow-x: scroll;
    }
 }
 
