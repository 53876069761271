.bl-scroller{

    height: 30px;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #fff;
    width: 50vw;
    margin: 0 auto;
    border-radius: 20px;
    margin-top: 20px;
}


.bl-scroller-w {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.bl-scroller-w > div {
    border-right: 2px solid rgba(255, 255, 255, 1);
    height: 16px;
    box-sizing: border-box;
    margin-top: 6px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 1;
}

